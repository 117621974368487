import React, { useEffect, useMemo } from 'react';
import { ScheduleStudent } from '../../../model/interfaces/student';
import LabLoader from '../../../components/LabLoader';
import { useSelector } from 'react-redux';
import { getRdpConnection } from '../../../store/slices/app/selectors';
import { RdpConnection } from '../../../model/interfaces/app';
import { Button } from 'antd';
import { DesktopOutlined } from '@ant-design/icons';
import GuacamoleClient from '../../../components/GuacamoleClient';
import { useParams } from 'react-router-dom';
import useAxios from '../../../hooks/useAxios';
import { DeviceStatus } from '../../../model/enum/DeviceStatus';

interface LabTabProps {
  schedule: ScheduleStudent;
  isLoading: boolean;
}

const LabTab = ({ schedule, isLoading }: LabTabProps) => {
  const { id }: any = useParams();
  const rdpConnection = useSelector(getRdpConnection);
  const { result, loading, run } = useAxios();

  useEffect(() => {
    if (result) {
      const element = document.createElement('a');
      const file = new Blob([result], {
        type: 'application/x-rdp',
      });
      element.href = URL.createObjectURL(file);
      element.download = 'config.rdp';
      document.body.appendChild(element);
      element.click();
      element.remove();
    }
  }, [result]);

  const percentage = useMemo(() => {
    if (schedule && schedule.lab && schedule.lab.devices) {
      if (schedule.lab.devices.some((d) => d.status === DeviceStatus.ERROR)) {
        return -1;
      }

      const totalProgress =
        schedule.lab.devices.reduce((a, b) => a + b.progress, 0) / schedule.lab.devices.length;
      return Math.round(totalProgress);
    } else {
      return 0;
    }
  }, [schedule]);

  const downloadRDPConfiguration = () => {
    fetch('https://api.ipify.org/?format=json')
      .then((response) => {
        return response.json();
      })
      .then((res: any) => {
        const publicIp = res['ip'];
        run({
          url: `schedule/rdp-config/lab/${id}/${publicIp}`,
          headers: { 'Content-Type': 'application/x-rdp' },
        });
      });
  };

  const message = useMemo(() => {
    return isLoading
      ? 'Getting lab information...'
      : percentage === -1
      ? 'Something went wrong!'
      : 'Preparing lab...';
  }, [isLoading, percentage]);

  const isGuacamole = rdpConnection === RdpConnection.HTML5;

  let domain = '';
  let username = '';
  let password = '';

  function isJumphost(element: any, index: number, array: any) {
    if (element.logicalName == 'Jumphost') {
      return true;
    }
    return false;
  }

  if (schedule) {
    let devices = schedule.exercise.exerciseEquipments;
    let jumphosts = devices.filter(isJumphost);
    domain = jumphosts[0].domain;
    username = jumphosts[0].username;
    password = jumphosts[0].password;
  }

  return (
    <div className={`flex-center ${isGuacamole ? 'max-height' : ''}`}>
      {percentage != 100 ? (
        <LabLoader message={message} percentage={percentage} devices={schedule?.lab?.devices} />
      ) : (
        <div className='full-width'>
          {isGuacamole ? (
            <GuacamoleClient labId={id} />
          ) : (
            <div>
              <div className='flex-center'>
                <table>
                  <tr>
                    <td className='credential-table-left'>Domain:</td>
                    <td className='credential-table-right'>{domain}</td>
                  </tr>
                  <tr>
                    <td className='credential-table-left'>Username:</td>
                    <td className='credential-table-right'>{username}</td>
                  </tr>
                  <tr>
                    <td className='credential-table-left'>Password:</td>
                    <td className='credential-table-right'>{password}</td>
                  </tr>
                </table>
              </div>
              <div className='p-25-0 flex-center'>
                <Button
                  icon={<DesktopOutlined />}
                  onClick={downloadRDPConfiguration}
                  loading={loading}>
                  Download RDP Configuration
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LabTab;
